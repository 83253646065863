import React, { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import Highcharts, { Options } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useDepthChartData } from './hook'
import { useCurrentLangManager, useDarkModeManager } from '../../state/user/hooks'
import { useTranslation } from 'react-i18next'
import { SettingChartType } from '../KLineChart/hook2'
import { BigNumber } from 'bignumber.js'
import { findFirstNonZeroIndex } from '../KLineChart/common'

const DepthChartsContainer = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 400px;
  overflow-x: hidden;
  position: relative;
`
const DepthChartsIframe = styled.div`
  display: block;
  box-sizing: border-box;
  width: calc(100% + 18px);
  height: 400px;
  margin-left: -9px;
  overflow-x: hidden;
  position: relative;
  background-color: transparent;
`
const defaultChartOptions: Partial<Options> = {
  title: undefined,
  lang: {},
  chart: {
    animation: true,
    height: 400,
    selectionMarkerFill: 'rgba(0,0,0, 0.1)',
    type: 'area',
    events: {
      //@ts-ignore
      selection: function(event: any) {
        // log the min and max of the primary, datetime x-axis
        // log the min and max of the y axis
        console.log(event.yAxis, event.yAxis)
      },
      load: function() {
        // @ts-ignore
        this.clickedOnce = false
      },
      click: function() {
        // @ts-ignore
        const chart = this
        // @ts-ignore
        if (chart.clickedOnce) {
          chart.zoomOut()
          // @ts-ignore
          chart.clickedOnce = false
        }
      }
    }
  },
  plotOptions: {
    area: {
      fillOpacity: 0.2,
      lineWidth: 1,
      step: 'center',
      events: {
        click: function(e) {
          const chart = this.chart,
            // yAxis = chart.yAxis[0],
            xAxis = chart.xAxis[0]
          // @ts-ignore
          let x, y, rangeX, rangeY
          // @ts-ignore
          if (!chart.clickedOnce) {
            // @ts-ignore
            x = xAxis.toValue(e.chartX)
            // @ts-ignore
            // y = yAxis.toValue(e.chartY)
            // @ts-ignore
            rangeX = xAxis.max - xAxis.min
            // // @ts-ignore
            // rangeY = yAxis.max - yAxis.min

            xAxis.setExtremes(x - rangeX / 10, x + rangeX / 10, false)
            // yAxis.setExtremes(y - rangeY / 10, y + rangeY / 10, false)
            chart.redraw()
            // @ts-ignore
            chart.clickedOnce = true
          } else {
            chart.zoomOut()
            // @ts-ignore
            chart.clickedOnce = false
          }
        }
      }
    }
  },
  loading: {
    hideDuration: 300,
    showDuration: 300
  },
  credits: {
    enabled: false
  },
  xAxis: {
    minPadding: 0,
    maxPadding: 0,
    tickWidth: 0,
    margin: 0,
    gridLineWidth: 0.5,
    gridLineColor: '#e6f1eb',
    lineColor: '#f5f5f5',
    labels: {
      style: {
        color: '#c6c6c6'
      },
      format: '{value:.5f}',
      y: 17
    },
    plotLines: [
      {
        color: '#888',
        value: 0.1523,
        width: 0,
        label: {
          text: '',
          rotation: 90
        }
      }
    ]
  },
  yAxis: [
    {
      opposite: true,
      lineWidth: 0,
      margin: 0,
      gridLineWidth: 0,
      allowDecimals: true,
      title: undefined,
      tickWidth: 0,
      tickLength: 0,
      tickPosition: 'inside',
      labels: {
        align: 'right',
        style: {
          color: 'transparent'
        },
        x: 0
      }
    },
    {
      opposite: false,
      linkedTo: 0,
      lineWidth: 0,
      margin: 0,
      gridLineWidth: 0,
      allowDecimals: true,
      title: undefined,
      tickWidth: 0,
      gridLineColor: '#e6f1eb',
      tickLength: 5,
      tickPosition: 'inside',
      labels: {
        align: 'left',
        format: '{value:.0f}',
        style: {
          color: '#938898'
        },
        x: 0
      }
    }
  ],
  legend: {
    enabled: false
  },
  tooltip: {
    // headerFormat: `<span style="font-size=10px;">Price: {point.key}</span><br/>`,
    // valueDecimals: 2
  },
  series: [
    {
      name: '买入',
      type: 'area',
      data: [],
      color: '#22c79d'
    },
    {
      name: '卖出',
      type: 'area',
      data: [],
      color: '#f26463'
    }
  ]
}
function handleOperatingDataRange(
  bidsWithStackedVolume: [number, number][],
  asksWithStackedVolume: [number, number][]
): {
  buy: [number, number][] | []
  sell: [number, number][] | []
  min: number
  max: number
} {
  if (!bidsWithStackedVolume.length || !asksWithStackedVolume.length) {
    return {
      buy: [],
      sell: [],
      min: 0,
      max: 0
    }
  }
  const series1 = bidsWithStackedVolume
  const series2 = asksWithStackedVolume
  let min = 0,
    max = 0
  //  卖出的  绝对差值
  const value1 = new BigNumber(series2[0][0])
    .minus(new BigNumber(series2[series2.length - 1][0]))
    .abs()
    .toNumber()
  //  买入 绝对 差
  const value2 = new BigNumber(series1[0][0])
    .minus(new BigNumber(series1[series1.length - 1][0]))
    .abs()
    .toNumber()
  if (new BigNumber(value2).gt(new BigNumber(value1))) {
    min = new BigNumber(series1[0][0]).minus(new BigNumber(value1)).toNumber()
    max = new BigNumber(series2[series2.length - 1][0]).toNumber()
  }
  if (new BigNumber(value1).gt(new BigNumber(value2))) {
    min = new BigNumber(series1[series1.length - 1][0]).toNumber()
    max = new BigNumber(series2[0][0]).plus(new BigNumber(value2)).toNumber()
  }
  const middleNum = new BigNumber(series1[0][0])
    .plus(new BigNumber(series2[0][0]))
    .dividedBy(2)
    .toNumber()
  if (middleNum && series1[0][0] !== series2[1][0]) {
    series1.unshift([middleNum, series1[0][0]])
    series2.unshift([middleNum, series2[0][1]])
  }
  return {
    buy: series1,
    sell: series2,
    min,
    max
  }
}
function definedFormatBigNumber(priceValue: string | number, setting: SettingChartType) {
  if (new BigNumber(priceValue).abs().lte(new BigNumber(0.0001))) {
    const totalStr = new BigNumber(priceValue).toFixed(12).toString()
    const zeroIndex = findFirstNonZeroIndex(totalStr)
    if (zeroIndex < 0) {
      return '0'
    }
    const ind = totalStr.substr(zeroIndex + 2, 3)
    return `${totalStr.indexOf('-') > -1 ? '-' : ''}0.{${zeroIndex}}${ind}`
    // return new BigNumber(priceValue).toFixed(10).toString()
  }
  if (new BigNumber(priceValue).abs().lte(new BigNumber(1))) {
    const totalStr = new BigNumber(priceValue).toFixed(12).toString()
    const zeroIndex = findFirstNonZeroIndex(totalStr)
    if (zeroIndex < 0) {
      return '0'
    }
    //  容易 出现 坐标轴 增宽  治标 不治本
    if (setting.decimals > 6) {
      return '0'
    }
    return new BigNumber(priceValue).toFixed(setting.decimals).toString()
  }
  return new BigNumber(priceValue).toFixed(2).toString()
}

export default function DepthCharts({ id, setting }: { id: string; setting: SettingChartType }) {
  const [darkMode] = useDarkModeManager()
  const { data } = useDepthChartData(id)
  const { t } = useTranslation()
  const [userLang] = useCurrentLangManager()
  const depthChartsRef = useRef<HighchartsReact.RefObject>(null)
  //  买入
  const bidsWithStackedVolume = useMemo<[number, number][]>(() => {
    return data.buy.length
      ? [...data.buy]
          .filter(item => {
            return !(Number(item.price) === 0 || !item.price)
          })
          .map((item, index) => {
            const totalAmount = data.buy.reduce(function(total, currentValue, currentIndex) {
              if (currentIndex > index) {
                return total
              }
              return total + Number(currentValue.remainAmount)
            }, 0)
            return [Number(item.price), totalAmount]
          })
      : []
  }, [data.buy])
  //  卖出
  const asksWithStackedVolume = useMemo<any[]>(() => {
    return data.sell.length
      ? [...data.sell]
          .filter(item => {
            return !(Number(item.price) === 0 || !item.price)
          })
          .map((item, index) => {
            const totalAmount = data.sell.reduce(function(total, currentValue, currentIndex) {
              if (currentIndex > index) {
                return total
              }
              return total + Number(currentValue.remainAmount)
            }, 0)
            return [Number(item.price), totalAmount]
          })
      : []
  }, [data.sell])
  useEffect(() => {
    if (depthChartsRef.current) {
      depthChartsRef.current.chart.update({
        chart: {
          backgroundColor: darkMode ? '#212429' : '#fff'
        },
        xAxis: {
          lineColor: darkMode ? '#5b5d69' : 'transparent',
          gridLineColor: darkMode ? '#36333c' : '#e6f1eb',
          labels: {
            style: {
              color: darkMode ? '#575965' : '#c6c6c6'
            }
          }
        },
        yAxis: [
          {
            labels: {
              style: {
                color: 'transparent'
              }
            }
          },
          {
            gridLineColor: darkMode ? '#36333c' : '#e6f1eb',
            labels: {
              style: {
                color: darkMode ? '#575965' : '#938898'
              }
            }
          }
        ]
      })
    }
  }, [darkMode])
  useEffect(() => {
    if (depthChartsRef.current) {
      const { buy: series1, sell: series2, min, max } = handleOperatingDataRange(
        bidsWithStackedVolume,
        asksWithStackedVolume
      )
      depthChartsRef.current.chart.update({
        xAxis: {
          min,
          max
        },
        series: [
          {
            ...defaultChartOptions.series![0],
            name: t('买入'),
            data: series1 as any
          },
          {
            ...defaultChartOptions.series![1],
            name: t('卖出'),
            data: series2 as any
          }
        ]
      })
    }
  }, [asksWithStackedVolume, bidsWithStackedVolume])
  useEffect(() => {
    if (setting.decimals && depthChartsRef.current) {
      depthChartsRef.current.chart.update({
        tooltip: {
          headerFormat: '',
          pointFormatter: function() {
            const xPrice = definedFormatBigNumber(this.x, setting)
            const name = this.series.name || 'Buy'
            return `
            <span style="font-size=10px;">${t('Price')}: ${xPrice}</span><br/>
            <span style="font-size=10px;">${t(name)}: ${this.y}</span><br/>
            `
            // '<span style="color:{' +
            // this.series.color +
            // '}">u25CF</span> {' +
            // this.series.name +
            // '}: <b>' +
            // this.y +
            // '</b>.'
          }
          // formatter: function() {
          //   return 'The value for <b>' + this.x + '</b> is <b>' + this.y + '</b>'
          // }
        },
        xAxis: {
          labels: {
            formatter: function() {
              const priceValue = this.axis.defaultLabelFormatter.call(this)
              return definedFormatBigNumber(priceValue, setting)
            }
          }
        }
      })
    }
  }, [setting])
  useEffect(() => {
    if (depthChartsRef.current) {
      const { buy: series1, sell: series2, min, max } = handleOperatingDataRange(
        bidsWithStackedVolume,
        asksWithStackedVolume
      )

      depthChartsRef.current.chart.update({
        xAxis: {
          min,
          max
        },
        tooltip: {
          // <span style="font-size=10px;">${t('Price')}: {point.key}</span><br/>
          headerFormat: ``
        },
        series: [
          {
            ...defaultChartOptions.series![0],
            name: t('买入'),
            data: series1 as any
          },
          {
            ...defaultChartOptions.series![1],
            name: t('卖出'),
            data: series2 as any
          }
        ]
      })
    }
  }, [userLang])
  return (
    <DepthChartsContainer>
      <DepthChartsIframe id="depth-container">
        <HighchartsReact ref={depthChartsRef} highcharts={Highcharts} options={defaultChartOptions} />
      </DepthChartsIframe>
    </DepthChartsContainer>
  )
}
