import { useCallback, useEffect, useRef, useState } from 'react'
import { CoinExType, getCoinExTypeData, getKLineData, KlineDataApiDataType } from '../../api/swap/klineData'
import { ChartData2Type } from './index'
import { BigNumber } from 'bignumber.js'
// import { bigNumberTransform } from '../../utils/common'
import { Currency } from '../../api/common'
import { filterKlineDataToHLWithDec } from './common'
import { useQuotesActionCoinInfo } from '../../state/quotes/hook'
import { bigNumberTransform, generateUniqueRandomString } from '../../utils/common'
import useWebSocket from '../../hooks/useWebSocket'
import emitter from '../../utils/emitter'

export interface MyCurrency extends Currency {
  address?: string
}

export interface SettingChartType {
  high: string
  low: string
  decimals: number
  maxVol: string
  minVol: string
  volDecimals: number
}

function handleFormatChartDataType(data: KlineDataApiDataType[]): ChartData2Type[] {
  if (!data || !data.length) {
    return []
  }

  const chartData: ChartData2Type[] = []
  for (let i = data.length - 1; i >= 0; i--) {
    const item = data[i]
    const turnover = new BigNumber(item.open)
      .plus(new BigNumber(item.close))
      .plus(new BigNumber(item.high))
      .plus(new BigNumber(item.low))
      .dividedBy(new BigNumber(4))
      .multipliedBy(new BigNumber(item.volume))
      .decimalPlaces(6)
      .toNumber()
    chartData.push({
      close: new BigNumber(item.close).toFixed(11),
      high: new BigNumber(item.high).toFixed(11),
      low: new BigNumber(item.low).toFixed(11),
      open: new BigNumber(item.open).toFixed(11),
      timestamp: item.created_time * 1000,
      volume: new BigNumber(item.volume).toFixed(11),
      turnover
    })
  }
  return chartData
}

export function useDefaultChartData({ id }: { id: string }) {
  //  是否展示K线图 setShowKLineChart
  const [kLineTimeState, setKLineTime] = useState<string>('1Min')
  const [chartData, setChartData] = useState<ChartData2Type[]>([])
  // 当前数据的最高值
  const [setting, setSetting] = useState<SettingChartType>({
    high: '',
    low: '',
    decimals: 5,
    maxVol: '',
    minVol: '',
    volDecimals: 2
  })
  const [isKLLoading, setIsKLLoading] = useState<boolean>(true)
  const [updateData, setUpdateData] = useState<ChartData2Type[]>([])
  const timeRef = useRef<NodeJS.Timeout | null>(null)
  const ignore = useRef(false)
  const { coinInfo, setCoinBaseInfo } = useQuotesActionCoinInfo()
  const { socket, disconnectedWS, initWebSocket } = useWebSocket({
    wsUrl: process.env.REACT_APP_WS_URL!,
    handleHBOperation: () => {
      const cacheUid = localStorage.getItem('cacheWSUid')
      let uid = generateUniqueRandomString()
      if (cacheUid === null || cacheUid === 'undefined') {
        localStorage.setItem('cacheWSUid', uid)
      } else {
        uid = cacheUid
      }
      socket.current &&
        socket.current!.send(
          JSON.stringify({
            type: 'login',
            uid
          })
        )
    },
    onMessage: (result: { code: string; type: 'market' | 'tradeList'; data: any; message: string }) => {
      const { type, data } = result
      if (
        Boolean(type === 'market' && data.dex_pairs_id != id) ||
        Boolean(type === 'tradeList' && data.pair_id != id)
      ) {
        return
      }
      if (type === 'tradeList') {
        emitter.emit('handleTradeLists', data)
        return
      }
      setUpdateData([...updateData, ...handleFormatChartDataType([data])])
    }
  })
  useEffect(() => {
    setUpdateData([])
  }, [kLineTimeState])
  useEffect(() => {
    return () => {
      timeRef.current && clearInterval(timeRef.current)
    }
  }, [])
  const fetchCurCoinInfo = useCallback(async () => {
    const CoinInfoData: CoinExType = await getCoinExTypeData({ id })
    if (!CoinInfoData || !Object.keys(CoinInfoData).length) {
      return
    }
    const price = bigNumberTransform(CoinInfoData.price, { decimalPlaces: CoinInfoData.priceDecimals })
    //  计算出 当前 数据 最后一条 最新价格  以及 数据的 差额
    const allInfoData = {
      ...CoinInfoData,
      price,
      // eslint-disable-next-line @typescript-eslint/camelcase
      isTrue: new BigNumber(CoinInfoData.change24h).gte(0)
    }
    if (CoinInfoData.info24h && CoinInfoData.info24h != null) {
      const coin24MaxPrice = bigNumberTransform(CoinInfoData.info24h.maxPrice, {
        decimalPlaces: CoinInfoData.priceDecimals > 3 ? 3 : CoinInfoData.priceDecimals
      })
      const coin24VolPrice = bigNumberTransform(CoinInfoData.info24h.vol, {
        decimalPlaces: CoinInfoData.priceDecimals > 3 ? 3 : CoinInfoData.priceDecimals
      })
      const coin24LowPrice = bigNumberTransform(CoinInfoData.info24h.minPrice, {
        decimalPlaces: CoinInfoData.priceDecimals > 3 ? 3 : CoinInfoData.priceDecimals
      })
      // const usdMarketValue = bigNumberTransform(CoinInfoData!.usd_market_value, { decimalPlaces: 3 })
      const coin24VolPrice5 = bigNumberTransform(CoinInfoData.info24h.volValue, {
        decimalPlaces: CoinInfoData.priceDecimals > 3 ? 3 : CoinInfoData.priceDecimals
      })
      allInfoData.info24h = {
        maxPrice: coin24MaxPrice,
        minPrice: coin24LowPrice,
        vol: coin24VolPrice,
        volValue: coin24VolPrice5
      }
    } else {
      allInfoData.info24h = coinInfo.info24h
    }
    if (allInfoData.token0Name && allInfoData.token1Name) {
      document.title = allInfoData.token0Name + ' / ' + allInfoData.token1Name
    }
    setCoinBaseInfo(allInfoData)
    return allInfoData
  }, [id, coinInfo])
  useEffect(() => {
    if (kLineTimeState === '' || !kLineTimeState) {
      // 关闭
      disconnectedWS()
      setChartData([])
      return
    } else if (!socket.current) {
      initWebSocket()
    }
    setIsKLLoading(true)
    ignore.current = false
    const fetchKlineData = async function () {
      try {
        const [KlineDataApiData, data2]: [KlineDataApiDataType[], any] = await Promise.all([
          getKLineData({
            id,
            type: kLineTimeState,
            size: '800'
          }),
          fetchCurCoinInfo()
        ])
        if (ignore.current) {
          return
        }
        const data = handleFormatChartDataType(KlineDataApiData)
        setChartData(data)
        setSetting(
          data2 && data2.priceDecimals
            ? {
              ...filterKlineDataToHLWithDec(data),
              decimals: data2.priceDecimals
            }
            : filterKlineDataToHLWithDec(data)
        )
        ignore.current = false
        timeRef.current && clearInterval(timeRef.current)
        timeRef.current = setInterval(() => {
          fetchCurCoinInfo()
        }, 5000)
      } catch (e) {
        setIsKLLoading(false)
        if (e instanceof Error) {
          console.error(e.message)
        }
      } finally {
        setIsKLLoading(false)
      }
    }
    fetchKlineData()
    return () => {
      ignore.current = true
    }
  }, [kLineTimeState])
  return {
    chartData,
    kLineTimeState,
    setKLineTime,
    coinInfo,
    updateData,
    setting,
    isKLLoading
  }
}
