import React from 'react'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { TradeRecordListItemType } from '../../api/swap/klineData'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { bigNumberTransform, formTimeStamp2DateTime5 } from '../../utils/common'
import { shortenAddress } from '../../utils'
import Copy from '../AccountDetails/Copy'

const QuotesTradeListItemRow = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.primary5};
  }
  @media (min-width: 415px) {
    .media-pc {
      max-width: 100%;
    }
  }
`
const QuotesTradeListItemRowItem = styled.a<{
  myActive?: boolean
  textAlign?: string
  maxWidth?: string
}>`
  height: 100%;
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 12px;
  display: block;
  flex: 1;
  text-decoration: none;
  word-break: break-all;
  color: ${({ theme, myActive }) => {
    if (typeof myActive === 'undefined') {
      return theme.text1
    }
    return myActive ? theme.green1 : theme.red1
  }};
  max-width: ${({ maxWidth }) => {
    if (typeof maxWidth === 'undefined') {
      return '100%'
    }
    return maxWidth
  }};
  text-align: ${({ textAlign }) => {
    if (typeof textAlign === 'undefined') {
      return 'left'
    }
    return textAlign
  }};
`
const QuotesTradeListItemRowItemSpan = styled.span<{
  myActive?: boolean
  textAlign?: string
}>`
  height: 100%;
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  flex: 1;
  text-decoration: none;
  color: ${({ theme, myActive }) => {
    if (typeof myActive === 'undefined') {
      return theme.text1
    }
    return myActive ? theme.green1 : theme.red1
  }};
  justify-content: ${({ textAlign }) => {
    if (typeof textAlign === 'undefined') {
      return 'flex-start'
    }
    return textAlign
  }};
  button {
    padding: 0;
  }
`

interface QuotesListCompeontProps {
  items: TradeRecordListItemType[]
  loadMore: (startIndex: number, stopIndex: number) => Promise<void> | void
  hasNextPage: boolean
}

const QuotesListComponent = ({ items, loadMore, hasNextPage }: QuotesListCompeontProps) => {
  const { t } = useTranslation()
  const Row = ({ index, style }: { data: TradeRecordListItemType[]; index: number; style: any }) => {
    if (!items[index]) {
      return <></>
    }
    const itemLoading = index === items.length
    if (itemLoading) {
      const content = 'Loading...'
      return (
        <div style={style} key={index}>
          {content}
        </div>
      )
    }

    const {
      // eslint-disable-next-line @typescript-eslint/camelcase
      deal_time,
      type,
      // eslint-disable-next-line @typescript-eslint/camelcase
      deal_price,
      // eslint-disable-next-line @typescript-eslint/camelcase
      token0_deal_amount,
      id,
      hash,
      // eslint-disable-next-line @typescript-eslint/camelcase
      sell_address,
      // eslint-disable-next-line @typescript-eslint/camelcase
      buy_address
    }: TradeRecordListItemType = items[index]
    const isBoolean = Boolean(type === '1')
    const formatPrice = bigNumberTransform(deal_price, { decimalPlaces: 5 })
    const formatVolume = bigNumberTransform(token0_deal_amount, { decimalPlaces: 3 })
    return (
      <QuotesTradeListItemRow key={id} style={style}>
        {/* eslint-disable-next-line @typescript-eslint/camelcase*/}
        <QuotesTradeListItemRowItem
          className="media-pc"
          maxWidth={'55px'}
          textAlign={'left'}
          href={'https://bscscan.com/tx/' + hash}
        >
          {/* eslint-disable-next-line @typescript-eslint/camelcase */}
          {deal_time && formTimeStamp2DateTime5(deal_time)}
        </QuotesTradeListItemRowItem>
        {/*  'https://bscscan.com/tx/' + hash*/}
        <QuotesTradeListItemRowItem
          maxWidth={'40px'}
          className="media-pc"
          textAlign={'left'}
          myActive={isBoolean}
          href={'https://bscscan.com/tx/' + hash}
        >
          {type === '1' ? t('Buy') : t('Sell')}
        </QuotesTradeListItemRowItem>
        <QuotesTradeListItemRowItem
          className="media-pc"
          maxWidth={'70px'}
          textAlign={'left'}
          href={'https://bscscan.com/tx/' + hash}
        >
          {formatPrice}
        </QuotesTradeListItemRowItem>
        <QuotesTradeListItemRowItem textAlign={'left'} href={'https://bscscan.com/tx/' + hash}>
          {formatVolume}
        </QuotesTradeListItemRowItem>
        <QuotesTradeListItemRowItemSpan textAlign={'flex-end'}>
          {/* eslint-disable-next-line @typescript-eslint/camelcase */}
          {shortenAddress(type === '1' ? buy_address : sell_address, 3)}
          {/* eslint-disable-next-line @typescript-eslint/camelcase */}
          <Copy isShowBox={false} hideText={true} toCopy={type === '1' ? buy_address : sell_address} size={'14'}>
            <span></span>
          </Copy>
        </QuotesTradeListItemRowItemSpan>
      </QuotesTradeListItemRow>
    )
  }

  const itemCount = hasNextPage ? items.length + 20 : items.length

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        // Use these actual sizes to calculate your percentage based sizes
        <InfiniteLoader isItemLoaded={index => index < items.length} itemCount={itemCount} loadMoreItems={loadMore}>
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              height={500}
              width={width}
              itemCount={itemCount}
              itemSize={40}
              onItemsRendered={onItemsRendered}
              ref={ref}
              overscanCount={20}
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  )
}

export default QuotesListComponent
